function numberCleaner(value?: string): string | undefined {
  const digits = value ? value.replace(/[^\d]/g, '') : value;
  return digits;
}

function checker(cnpj?: string): boolean {
  const excludeArray = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999',
  ];

  if (!cnpj) {
    return true;
  }

  if (cnpj.length !== 14) {
    return false;
  }

  if (excludeArray.some(item => cnpj === item)) {
    return false;
  }

  return true;
}

function digitChecker(cnpj?: string, weights?: number[]) {
  if (!cnpj || !weights) {
    return true;
  }

  const numbers = cnpj.split('').slice(0, weights.length);

  // sum numbers of CNPJ based in weights
  const sum = numbers.reduce(
    (accumulator, number, index) =>
      accumulator + Number(number) * weights[index],
    0,
  );

  const rest = sum % 11;
  const digit = rest < 2 ? 0 : 11 - rest;
  return parseInt(cnpj[weights.length], 10) === digit;
}

export function cnpjValidator(value?: string): boolean {
  if (!value) {
    return false;
  }

  const cnpj = numberCleaner(value);

  const wightDigit1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  const wightDigit2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  return (
    checker(cnpj) &&
    digitChecker(cnpj, wightDigit1) &&
    digitChecker(cnpj, wightDigit2)
  );
}
