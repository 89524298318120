import { useContext } from 'react';

import { AuthContext, AuthContextData } from 'contexts';

import { useBloc } from './bloc';
import { AuthProviderProps } from './types';

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const { memoValue } = useBloc();

  return (
    <AuthContext.Provider value={memoValue}>{children}</AuthContext.Provider>
  );
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}
