/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteConfigItem } from 'models';

function generateRouteID(key: string): string {
  const prefix =
    process.env.REACT_APP_STORAGE_PREFIX || '@portalservicos-digix';
  return `${prefix}:${key}`;
}

export const routesConfig: RouteConfigItem[] = [
  {
    id: generateRouteID('home'),
    path: '/',
    elementPath: 'Home',
    isPrivate: false,
    redirectPath: '/',
  },
  {
    id: generateRouteID('login'),
    path: '/login',
    elementPath: 'Login',
    isPrivate: false,
    redirectPath: '/',
  },
  {
    id: generateRouteID('definicaosenha'),
    path: '/definicaosenha',
    elementPath: 'DefinicaoSenha',
    isPrivate: false,
    redirectPath: '/',
  },
  {
    id: generateRouteID('notificacoes'),
    path: '/fornecedores/notificacoes',
    elementPath: 'Notificacoes',
    routeTitle: 'Notificacoes',
    isPrivate: true,
  },
  {
    id: generateRouteID('reclamacoesVerificaGeolocalizacao'),
    path: '/reclamacoes',
    elementPath: 'ReclamacoesVerificarGeolocalizacao',
    isPrivate: false,
    redirectPath: '/',
  },
  {
    id: generateRouteID('reclamacoesLogin'),
    path: '/reclamacoes/login',
    elementPath: 'ReclamacoesLogin',
    isPrivate: false,
    redirectPath: '/',
  },
  {
    id: generateRouteID('reclamacoesPrimeiroAcesso'),
    path: '/reclamacoes/primeiro-acesso',
    elementPath: 'ReclamacoesPrimeiroAcesso',
    isPrivate: false,
    redirectPath: '/',
  },
  {
    id: generateRouteID('reclamacoesEsqueciSenha'),
    path: '/reclamacoes/esqueci-senha',
    elementPath: 'ReclamacoesEsqueciSenha',
    isPrivate: false,
    redirectPath: '/',
  },
  {
    id: generateRouteID('reclamacoesEsqueciSenha'),
    path: '/reclamacoes/definir-senha',
    elementPath: 'ReclamacoesDefinirSenha',
    isPrivate: false,
    redirectPath: '/',
  },
  {
    id: generateRouteID('reclamacoesAcesso'),
    path: '/reclamacoes/acesso',
    elementPath: 'ReclamacoesAcesso',
    isPrivate: false,
    redirectPath: '/',
  },
  {
    id: generateRouteID('criarconta'),
    path: '/reclamacoes/criar-conta',
    elementPath: 'CriarConta',
    isPrivate: false,
    redirectPath: '/',
  },
  {
    id: generateRouteID('criarconta'),
    path: '/reclamacoes/criar-conta',
    elementPath: 'CriarConta',
    isPrivate: false,
    redirectPath: '/',
  },
  {
    id: generateRouteID('dashboard'),
    path: '/reclamacoes/dashboard',
    elementPath: 'Dashboard',
    isPrivate: true,
  },
  {
    id: generateRouteID('meus-dados'),
    path: '/reclamacoes/meus-dados',
    elementPath: 'MeusDados',
    isPrivate: true,
  },
  {
    id: generateRouteID('novareclamacao'),
    path: '/reclamacoes/nova-reclamacao',
    elementPath: 'NovaReclamacao',
    isPrivate: true,
  },
  {
    id: generateRouteID('detalhesDaReclamacao'),
    path: '/reclamacoes/detalhes',
    elementPath: 'DetalhesDaReclamacao',
    isPrivate: true,
  },
  {
    id: generateRouteID('FaleConoscoReCaptcha'),
    path: '/fale-conosco',
    elementPath: 'FaleConoscoReCaptcha',
    isPrivate: false,
  },
  {
    id: generateRouteID('faleConoscoOpcoes'),
    path: '/fale-conosco/opcoes',
    elementPath: 'FaleConoscoOpcoes',
    isPrivate: true,
  },
  {
    id: generateRouteID('faleConoscoDuvidas'),
    path: '/fale-conosco/duvidas',
    elementPath: 'FaleConoscoDuvidas',
    isPrivate: true,
  },
  {
    id: generateRouteID('FaleConoscoSugestoes'),
    path: '/fale-conosco/sugestoes',
    elementPath: 'FaleConoscoSugestoes',
    isPrivate: true,
  },
  {
    id: generateRouteID('CanalDeDenunciasReCaptcha'),
    path: '/canal-de-denuncias',
    elementPath: 'CanalDeDenunciasReCaptcha',
    isPrivate: false,
  },
  {
    id: generateRouteID('CanalDeDenunciasFormulario'),
    path: '/canal-de-denuncias/formulario',
    elementPath: 'CanalDeDenunciasFormulario',
    isPrivate: true,
  },
];
