export function censurarNumeroTelefone(telefone?: string): string {
  if (!telefone) return '';
  const apenasDigitos = telefone.replace(/\D/g, ''); // Remover caracteres não numéricos
  const digitosVisiveis = 4; // Número de dígitos visíveis a serem mantidos no final
  const digitosCensurados = apenasDigitos.length - digitosVisiveis;

  if (digitosCensurados <= 0) {
    // Se o número de telefone tiver quatro ou menos dígitos, mantemos eles visíveis.
    return telefone;
  }
  // Censurar todos os dígitos exceto os últimos digitosVisiveis.
  const parteCensurada = '*'.repeat(digitosCensurados);
  const parteVisivel = apenasDigitos.slice(-digitosVisiveis);
  return telefone.replace(apenasDigitos, parteCensurada + parteVisivel);
}
