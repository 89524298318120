export const selecionarIconeDoArquivo = (nome: string): string => {
  const tiposDeVideo = ['mp4', 'm4v', 'mkv', 'webm'];
  const tiposDeImagem = ['jpg', 'jpeg', 'png', 'tiff', 'heif', 'heic'];
  const tiposDeAudio = ['ogv', 'ogg', 'oga', 'flac', 'mp3', 'opus'];
  const extensao = nome.split('.').pop();

  if (extensao) {
    if (tiposDeVideo.includes(extensao)) {
      return 'fa-file-video';
    }
    if (tiposDeImagem.includes(extensao)) {
      return 'fa-file-image';
    }
    if (tiposDeAudio.includes(extensao)) {
      return 'fa-file-audio';
    }
  }
  return 'fa-file-lines';
};
