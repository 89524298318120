/* eslint-disable react/function-component-definition */
import { useCallback, lazy, Suspense, ComponentType } from 'react';

import { AnimatePresence as AnimatePresenceOriginal } from 'framer-motion';
import { Routes, useLocation, Route, Navigate } from 'react-router-dom';

import type { RouteConfigItem, RouteProps } from 'models';
import { AppLayout } from 'layouts';

import { RouteContext } from './components';
import { RouterCreatorProps } from './types';

const AnimatePresence: any = AnimatePresenceOriginal;

export function RouterCreator({
  routesConfig,
}: RouterCreatorProps): JSX.Element {
  const location = useLocation();

  const privateRoutes = routesConfig.filter(route => route.isPrivate);
  const publicRoutes = routesConfig.filter(route => !route.isPrivate);

  const importComponent = useCallback((elementPath: string) => {
    return lazy<ComponentType<RouteProps>>(
      () => import(`../pages/${elementPath}` as const),
    );
  }, []);

  const RenderElement = useCallback(
    ({ route }: { route: RouteConfigItem }) => {
      const Element = route?.elementPath
        ? importComponent(route.elementPath)
        : route.element;
      const routeProps: RouteProps = {
        name: route?.name,
        routeTitle: route?.routeTitle,
        path: route.path,
      };

      return (
        <Suspense>
          <RouteContext route={route}>
            {Element && <Element {...routeProps} />}
          </RouteContext>
        </Suspense>
      );
    },
    [importComponent],
  );

  return (
    <AnimatePresence initial mode="wait">
      <Routes location={location} key={location.pathname}>
        {publicRoutes.map(route => (
          <Route
            key={route.id}
            path={route.path}
            element={<RenderElement route={route} />}
          />
        ))}
        <Route element={<AppLayout />}>
          {privateRoutes.map(route => (
            <Route
              key={route.id}
              path={route.path}
              element={<RenderElement route={route} />}
            />
          ))}
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </AnimatePresence>
  );
}
