import { ReactNode } from 'react';

import { RouteConfigItem } from 'models';

interface RouteContextProps {
  children: ReactNode;
  route: RouteConfigItem;
}

export function RouteContext({
  children,
  route,
}: RouteContextProps): JSX.Element {
  if (route?.context) {
    return <route.context>{children}</route.context>;
  }
  return <>{children}</>;
}
