export function capitalize(text: string): string {
  return text.replace(/\b\w/g, char => char.toUpperCase());
}

export function snakeCaseToPascalCase(text: string): string {
  const replacedUnderlineText = text.replace(/_/g, ' ');
  return capitalize(replacedUnderlineText).replace(/[\s ]/g, '');
}

export function resizeString(
  str: string,
  size: number | undefined = 22,
): string {
  if (str.length <= size) {
    return str;
  }
  return `${str
    .split('')
    .splice(0, size - 3)
    .join('')}...`;
}

export function stripTags(html: string, replaceBy = ''): string {
  return html.replace(/(<([^>]+)>)/gi, replaceBy);
}

export function strNormalize(str: string | number | boolean): string {
  return String(str)
    .normalize('NFD')
    .replace(/[^a-zA-Z0-9\s]/g, '');
}
