interface CustomFileOptions extends FilePropertyBag {
  copied?: boolean;
}

export class CustomFile extends File {
  public copied?: boolean;

  constructor(
    fileBits: BlobPart[],
    fileName: string,
    options?: CustomFileOptions,
  ) {
    const { copied, ...restOptions } = options || {};
    super(fileBits, fileName, restOptions);
    this.copied = copied;
  }
}
