import {
  GerenciadorDeMensagem,
  CriarMensagemParams,
} from '@bit/digix.digixui.mensagens-flutuantes';

export function useGerenciadorDeMensagens(): (
  params: CriarMensagemParams,
) => void {
  function criarMensagem(params: CriarMensagemParams): void {
    GerenciadorDeMensagem.criarMensagem({ ...params, tempoDeExibicao: 5000 });
  }

  return criarMensagem;
}
