import { useState, useCallback, useMemo, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { AuthState, AuthContextData } from 'contexts';
import { servicoBase } from 'services/servico-base';
import { verificarSeAceitouTermos, getHashedUrlTermos } from 'services/termos';
import { parsedLocalStorage } from 'utils';
import { Login } from 'models/login';

export function useBloc() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [dispositivoId] = useState<string>(() => {
    const localDispositivoId = localStorage.getItem('user:device-id');

    if (localDispositivoId !== null) {
      return localDispositivoId;
    }

    const newdispositivoId = uuid();

    localStorage.setItem('user:device-id', newdispositivoId);

    return newdispositivoId;
  });

  const [data, setData] = useState<AuthState>(() => {
    const nome = localStorage.getItem('nome');
    const documento = localStorage.getItem('documento');
    const token = localStorage.getItem('token');
    const ip = localStorage.getItem('ip');
    const geoLocalizacao = localStorage.getItem('geolocalizacao');
    const tipoCadastro = localStorage.getItem('tipoCadastro');

    if (ip) servicoBase.defaults.headers.common['x-portalServicos-IP'] = ip;
    if (geoLocalizacao)
      servicoBase.defaults.headers.common['x-portalServicos-Geolocalizacao'] =
        geoLocalizacao;

    if (token) {
      servicoBase.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    return {
      nome,
      token: token || '',
      documento: documento || undefined,
      ip,
      geoLocalizacao,
      tipoCadastro,
    };
  });

  const deslogar = useCallback(() => {
    try {
      const storageddispositivoId = localStorage.getItem('user:device-id');
      localStorage.clear();

      if (storageddispositivoId) {
        localStorage.setItem('user:device-id', storageddispositivoId);
      }
      navigate('/');
    } catch (error) {
      console.info({ error });
    }
  }, [navigate]);

  const mudarUsuario = useCallback((user: any) => {
    setData(oldState => ({
      ...oldState,
      user,
    }));

    parsedLocalStorage.setItem('user', user);
  }, []);

  const mudarToken = useCallback((newToken: string) => {
    setData(oldState => ({
      ...oldState,
      token: newToken,
    }));

    localStorage.setItem('token', newToken);
  }, []);

  const mudarIpGeolocalizacao = useCallback(
    (ip: string, geolocalizacao: string) => {
      setData(oldState => ({
        ...oldState,
        ip,
        geolocalizacao,
      }));

      localStorage.setItem('ip', ip);
      localStorage.setItem('geolocalizacao', geolocalizacao);
    },
    [],
  );

  const logar = useCallback(
    async (
      login: Login,
      tipoCadastro: string,
      urlParaRedirecionar?: string,
    ) => {
      try {
        localStorage.setItem('token', login.token);
        localStorage.setItem('nome', login.nome);
        localStorage.setItem('documento', login.documento);
        localStorage.setItem('tipoCadastro', tipoCadastro);
        setData(oldState => ({
          ...oldState,
          nome: login.nome,
          documento: login.documento,
          token: login.token,
          tipoCadastro,
        }));
        servicoBase.defaults.headers.common.Authorization = `Bearer ${login.token}`;
      } catch (error) {
        console.info({ error });
      } finally {
        setTimeout(() => {
          verificarSeAceitouTermos(login.documento, tipoCadastro).then(
            (aceitouTermos: boolean) => {
              if (aceitouTermos && urlParaRedirecionar) {
                navigate(urlParaRedirecionar);
              } else if (urlParaRedirecionar) {
                getHashedUrlTermos(
                  login.documento,
                  tipoCadastro,
                  urlParaRedirecionar,
                ).then(url => {
                  window.location.href = url;
                });
              }
            },
          );
        }, 1000);
      }
    },
    [navigate],
  );

  const validarCadastro = useCallback((documento: string) => {
    setData(oldState => ({
      ...oldState,
      documento,
    }));

    parsedLocalStorage.setItem('documento', documento);
  }, []);

  const memoValue: AuthContextData = useMemo(() => {
    return {
      token: data?.token,
      nome: data?.nome,
      tipoCadastro: data?.tipoCadastro,
      documento: data?.documento,
      geoLocalizacao: data?.geoLocalizacao,
      logar,
      deslogar,
      validarCadastro,
      mudarUsuario,
      mudarToken,
      mudarIpGeolocalizacao,
      loading,
      dispositivoId,
    };
  }, [
    data?.token,
    data?.nome,
    data?.tipoCadastro,
    data?.documento,
    data?.geoLocalizacao,
    logar,
    deslogar,
    validarCadastro,
    mudarUsuario,
    mudarToken,
    mudarIpGeolocalizacao,
    loading,
    dispositivoId,
  ]);

  const verificarSeEstaLogado = useCallback((): boolean => {
    const loadingDiv = document.getElementById('pre-loading') as HTMLDivElement;
    loadingDiv.setAttribute('style', 'display: none;');

    const { token } = data;

    if (!token) {
      setLoading(() => false);
      return false;
    }

    setLoading(() => false);

    return true;
  }, [data]);

  useEffect(() => {
    if (!window.name) {
      window.name = uuid().replaceAll(/-/g, '');
    }
  }, []);

  useEffect(() => {
    window.deslogar = deslogar;
  }, [deslogar]);

  useEffect(() => {
    verificarSeEstaLogado();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    memoValue,
    loading,
  };
}
