// Função para calcular a idade com base na data de nascimento
export function calcularIdade(dataNascimento: Date | undefined): number {
  if (dataNascimento === undefined) return 0;
  const dataAtual = new Date();
  const anoNascimento = new Date(dataNascimento).getFullYear();
  const mesNascimento = new Date(dataNascimento).getMonth();
  const diaNascimento = new Date(dataNascimento).getDate() + 1;
  const anoAtual = dataAtual.getFullYear();
  const mesAtual = dataAtual.getMonth();
  const diaAtual = dataAtual.getDate();

  let idade = anoAtual - anoNascimento;

  if (
    mesAtual < mesNascimento ||
    (mesAtual === mesNascimento && diaAtual < diaNascimento)
  ) {
    // eslint-disable-next-line no-plusplus
    idade--;
  }

  return idade;
}
