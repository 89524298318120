import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppContext } from 'contexts';
import { AuthProvider } from 'hooks';

function App(): JSX.Element {
  console.info(`Versão: ${process.env.REACT_APP_VERSION || 'Desenvolvimento'}`);
  const queryClient = new QueryClient();

  return (
    <BrowserRouter>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <AppContext />
        </QueryClientProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
