import { servicoBase } from '../servico-base';

export async function visualizarTermos(tipoCadastro: string): Promise<string> {
  const cadastroTipo: boolean = tipoCadastro === 'USUARIO_FORNECEDOR';
  try {
    const { data: result } = await servicoBase.get<{ url: string }>(
      `/v1/termosdeuso/termodeuso`,
      {
        params: {
          produto: cadastroTipo
            ? process.env.REACT_APP_PRIVACY_POLICY_NOTIFICACAO_ENV
            : process.env.REACT_APP_PRIVACY_POLICY_RECLAMACAO_ENV,
        },
      },
    );
    return result?.url;
  } catch (error) {
    return `https://termos.digix.com.br/termos/visualizacao?produto=${
      cadastroTipo
        ? process.env.REACT_APP_PRIVACY_POLICY_NOTIFICACAO_ENV
        : process.env.REACT_APP_PRIVACY_POLICY_RECLAMACAO_ENV
    }`;
  }
}

export async function verificarSeAceitouTermos(
  cpf: string,
  tipoCadastro: string,
): Promise<boolean> {
  const cadastroTipo: boolean = tipoCadastro === 'USUARIO_FORNECEDOR';
  try {
    const { data: result } = await servicoBase.get<boolean>(
      `/v1/termosdeuso/verificartermodeuso`,
      {
        params: {
          login: cpf,
          nomeProduto: cadastroTipo
            ? process.env.REACT_APP_PRIVACY_POLICY_NOTIFICACAO_ENV
            : process.env.REACT_APP_PRIVACY_POLICY_RECLAMACAO_ENV,
        },
      },
    );

    return result || false;
  } catch {
    return false;
  }
}

export async function getHashedUrlTermos(
  cpf: string,
  tipoCadastro: string,
  urlParaRedirecionar: string,
): Promise<string> {
  const cadastroTipo: boolean = tipoCadastro === 'USUARIO_FORNECEDOR';
  try {
    const { data: result } = await servicoBase.get<{ url: string }>(
      `/v1/termosdeuso/solicitaraceite`,
      {
        params: {
          login: cpf,
          nomeProduto: cadastroTipo
            ? process.env.REACT_APP_PRIVACY_POLICY_NOTIFICACAO_ENV
            : process.env.REACT_APP_PRIVACY_POLICY_RECLAMACAO_ENV,
          returnUrl: `${window.location.origin}${urlParaRedirecionar}`,
        },
      },
    );

    return result?.url || '';
  } catch {
    return '';
  }
}
