/* eslint-disable no-plusplus */

export const FORMATO_NUMERO_ATENTIMENTO = '##.##.####.###.#####-#';

export function formatarString(valor: string, padrao: string): string {
  if (!valor || !padrao) return '';

  let i = 0;
  const v = valor.toString();
  const result = padrao.replace(/#/g, _ => v[i++]);
  if (valor.replace(/\s/g, '').length < 9) {
    return result.slice(0, result.indexOf('u'));
  }
  return result;
}
