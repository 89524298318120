import { createContext } from 'react';

import { Login } from 'models/login';

export interface AuthState {
  nome: string | null;
  token: string;
  documento: string | undefined;
  ip: string | null;
  geoLocalizacao: string | null;
  tipoCadastro: string | null;
}

export type GetTemPermissaoFnOpcoes = {
  temQualquerPermissao?: boolean;
  temTodasAsPermissoes?: boolean;
};

export type GetHasPermissionsFn<T = any> = (
  permissions: T[],
  options?: GetTemPermissaoFnOpcoes,
) => boolean;

export interface AuthContextData {
  token?: string;
  papelZeroToken?: string;
  nome?: string | null;
  tipoCadastro?: string | null;
  dispositivoId: string;
  lotacao?: number;
  documento?: string | null;
  geoLocalizacao?: string | null;
  logar(login: Login, tipoCadastro: string, urlParaRedirecionar?: string): void;
  deslogar(): void;
  validarCadastro(documento: string): void;
  mudarUsuario(user: any): void;
  mudarToken(newToken: string): void;
  mudarIpGeolocalizacao(ip: string, geolocalizacao: string): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);
