import { useCallback } from 'react';
import { visualizarTermos } from 'services/termos';

export default function useBloc() {
  const handleTermosDeUso = useCallback((tipoCadastro: string) => {
    visualizarTermos(tipoCadastro).then(url => {
      window.open(url, '_blank');
    });
  }, []);

  return { handleTermosDeUso };
}
