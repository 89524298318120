export function censurarEmail(email?: string): string {
  if (!email) return '';
  const [usuario, dominio] = email.split('@');
  const usuarioLength = usuario.length;

  if (usuarioLength <= 2) {
    return `${usuario[0]}${'*'.repeat(usuarioLength - 1)}@${dominio}`;
  }

  const nomeDeUsuarioCensurado = `${usuario[0]}${'*'.repeat(
    usuarioLength - 2,
  )}${usuario[usuarioLength - 1]}`;

  return `${nomeDeUsuarioCensurado}@${dominio}`;
}
