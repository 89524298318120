/* eslint-disable no-new-func */
import { stripTags } from './string';

import { CreateObjectParams } from './types';

export function recursiveCreateDeepObject<V = any>(
  path: string,
  value: Record<string, V>,
): Record<string, V> {
  const [pathSplitted, ...rest] = path.split('.');

  const internObject: any = {};

  internObject[pathSplitted] = value;

  if (rest.length) {
    return recursiveCreateDeepObject(rest.join('.'), internObject);
  }

  return internObject;
}

export function createDeepObjectWithValue<T = any, R = any>({
  path,
  value,
  parameter,
}: CreateObjectParams<T>): R {
  const formattedPath = `${path}.${parameter}`.split('.').reverse().join('.');
  const object = recursiveCreateDeepObject(formattedPath, value as any);

  return object as R;
}

export function getObjectValueByStringPath<R = any>(
  obj: Record<string, any>,
  path: string,
): any {
  const formattedPath = stripTags(path)
    .split('.')
    .map(param => (!Number.isNaN(parseInt(param, 10)) ? `[${param}]` : param))
    .join('?.')
    .replace(/(\.\?\[)/g, '?[');

  const isEmptyObject = !Object.keys(obj).length;

  if (isEmptyObject || !obj) {
    return '';
  }

  const value = new Function('_', `return _.${formattedPath}`)(obj);

  return value as R;
}
