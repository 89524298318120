export function formatarData(data: string): string {
  const conversao = new Date(data);
  const ano = conversao.getFullYear();
  const mm = conversao.getMonth() + 1;
  const dd = conversao.getDate();

  return `${dd < 10 ? `0${dd}` : dd}/${mm < 10 ? `0${mm}` : mm}/${ano}`;
}

export function formatarDataEhora(data: string): JSX.Element {
  const dataHora = new Date(data);
  const dia = dataHora.getDate();
  const mes = dataHora.toLocaleString('default', { month: 'long' });
  const ano = dataHora.getFullYear();
  const hora = `0${dataHora.getHours()}`.slice(-2);
  const minutos = `0${dataHora.getMinutes()}`.slice(-2);

  const dataHoraFormatada = (
    <>
      <strong>
        {dia} de {mes} de {ano}
      </strong>{' '}
      - {hora}:{minutos}
    </>
  );

  return dataHoraFormatada;
}
